.header {
  width: 100%;
  height: 90px;
  line-height: 80px;
  background-color: #081b29;
}

.nav__item {
  margin-right: 20px;
  color: #fff;
}


.nav__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  width: 15%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-image {
  margin-right: 10px; /* Adjust the spacing as needed */
}

.logo-text {
  font-size: 1.5rem; /* Adjust the font size as needed */
  color: var(--secondary-color); /* Adjust the color as needed */
}

.header-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.menu {
  margin-bottom: 0;
}

.nav__item a {
  text-decoration: none;
  color: #cc8a0e;
  font-weight: 500;
  font-size: 1.1rem;
}

.nav__item a:hover {
  color: inherit;
}

.nav__item a.active__link {
  color: var(--secondary-color);
}

.mobile__menu {
  font-size: 1.3rem;
  color:  var(--secondary-color);
  display: none;
}

.information {
  display: flex;
  align-items: end;
  text-align: end;
  justify-content: space-between;

}

.sticky__header {
  width: 100%;
  height: 90px;
  line-height: 80px;
  background: #081b29;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 8px -3px #ddd;
  z-index: 999;
}

.language-selector {
  display: flex;
  flex-direction: column;
  text-align: end;
  margin: 0 10 0 10;
}

.language-dropdown {
  margin-bottom: 10px;
  padding: 5px;
  font-size: 16px;
}

.dropdown-icons {
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dropdown-item .fi {
  margin-right: 8px;
}

.dropdown-item.selected {
  font-weight: bold;
  color: #007bff;
}

.mb-0{
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: start;
  justify-content: center;
  align-items: center;
}

/* responsive */

@media only screen and (max-width: 992px) {
  .logo img {
    width: 12%;
  }

  .header-title {
    font-size: 1 rem;
  }

  .navigation {
    width: 50%;
    height: 90%;
    position: fixed;
    top: 10%;
    left: 0;
    background: #081b29;
    color: rgb(190, 142, 36);
    z-index: 999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 50%);
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    line-height: 0.5rem;
    justify-content: center;
    z-index: 9999;
  }

 
}

@media only screen and (max-width: 768px) {
  .logo img {
    width: 15%;
  }
  .header-title {
    font-size: 0.85rem;
  }
  .logo-text {
    font-size: 0.9rem;
  }


  .show__menu {
    display: block;
    color: #fff;
  }

  .mobile__menu {
    display: block;
  }

  .menu {
    width: calc(100% -50%);
  }

  .information {
    display: none;
  }

  
}

@media screen and (max-width: 500px) {

  .logo-text {
    font-size: 1rem;
  }
  /* .language-dropdown {
    padding: 3px;
    font-size: 0.7rem;
  }
  .information, .language__selector{
    display: none;
  } */


}