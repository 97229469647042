.data__table {
  width: 100%;
  margin-top: 20px;
}

.data__table h1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 30px;
}

/* .cow__value {
  display: flex;
  flex-direction: row;
  align-items: center;
} */
.cow__value {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.row__price {
  text-align: right;
  width: 100%;
}

.search-bar {
  border-radius: 2px;
}

.cow__price {
  padding: 2px;
  font-size: 1.1rem;
  color: var(--secondary-color);
}

.cow__value .search-bar input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.search-bar input {
  width: 10rem;
}

.cow__value h2 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--secondary-color);
}

/* table */

/* table {
  width: 696px;
  height: 440px;
  border-collapse: collapse;
  border-radius: 10px !important;
  border-style: hidden;
  box-shadow: 0 0 0 1px #00abf0;
  text-align: center;
  color: #ffffff;
  overflow-y: auto;
} */

table {
  width: 696px;
  height: 50px;
  text-align: center;
  color: #ffffff;
}

.table-container {
  position: relative;
  
  border-collapse: collapse;
  border-radius: 10px !important;
  border-style: hidden;
  box-shadow: 0 0 0 1px #00abf0;
}

.table-body-container {
  max-height: 440px;

  overflow-y: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.table-body-container::-webkit-scrollbar { 
  display: none; 
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}


.table-container thead th tr {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  font-size: 12px; 
  padding: 4px;
}




td {
  border: 0px solid #00abf0 !important;
}

table tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

table tr:last-child td:first-child {
  border-top-right-radius: 5px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

table tr:last-child th:first-child {
  border-bottom-left-radius: 5px;
}

th {
  border: 0px solid #00abf0 !important;
}

th,
td {
  padding: 0.5em;
  border-bottom: 1px solid white;
}

body {
  margin: 1.5em;
}

.th-right {
  height: 50px;
  text-align: center;
  font-weight: 500;
  font-style: Bold;
  font-size: 1.3rem;
  font-family: "Times New Roman", Times, serif;
  color: var(--secondary-color);
}

.content {
  color: #ffffff;
  padding-left: 10px;
}

/* Search bar */
.highlight {
  background-color: rgb(122, 221, 238);
  color: #0f0f0f;
}

.search-bar {
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
}

/* Pagination feature */
.pagination-feature {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination-button {
  width: 120px;
  height: 40px;
  border: none;
  background-color: #00abf0;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background-color: #00abf0;
}
/* LOAD DATA */

.load {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}
.load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child {
  background: #19a68c;
  animation-delay: -1.5s;
}
.load :nth-child(2) {
  background: #f63d3a;
  animation-delay: -1s;
}
.load :nth-child(3) {
  background: #fda543;
  animation-delay: -0.5s;
}
.load :last-child {
  background: #193b48;
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}
.value-up span {
  color: green;
}

.value-down span {
  color: red;
}

@media only screen and (max-width: 1400px) {
  .search-bar input {
    width: 8rem;
  }

  table {
    width: 100%;
  }
}

@media only screen and (max-width: 1240px) {
  .search-bar input {
    width: 7rem;
  }

  .cow__value h2 {
    font-size: 0.7rem;
    font-weight: 400;
    color: var(--secondary-color);
  }
}

@media only screen and (max-width: 992px) {
  .data__table {
    width: 60%;
  }
  
  .pagination-button {
    width: 100px;
    height: 30px;
    font-size: 0.8rem;
  }

  .search-bar input {
    width: 9rem;
  }

  .cow__value h2 {
    font-size: 1rem;
    font-weight: 500;
    color: var(--secondary-color);
  }

  .th-right {
    font-size: 1.1rem;
  }

}

@media only screen and (max-width: 576px) {
 
  .th-right {
    font-weight: 450;
    font-size: 0.9rem;  
  }


  td{
    font-size: 0.7rem;
  }

  .pagination-button {
    width: 60px;
    height: 20px;
    font-size: 0.5rem;
  }

  .search-bar input {
    width: 6rem;
  }

  .cow__value h2 {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--secondary-color);
  }

  .cow__price {
    display: none;
    font-size: 0.75rem;
    font-weight: 450;
  }
}
