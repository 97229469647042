.calendar {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 5px;
  background-color: var(--third-color);
}

.border {
  border: 2px solid #5bc8fa;
}

.rounded {
  border-radius: 5px;
  background-color: var(--secondary-color);
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker__input-container input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

@media only screen and (max-width: 992px) {
  .calendar {
    width: 80px;
    margin-left: 10px;
  }
  .react-datepicker__input-container input {
    width: 130%;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 576px) {
  .calendar {
    width: 60px;
    margin-left: 5px;
  }

  .react-datepicker__input-container input {
    width: 130%;
    font-size: 0.7rem;
  }

  .react-datepicker {
    width: 100%;
    background-color: #fff;
    top: 50%;
    left: 50%;    
  }
}
