.login__img {
  width: 60%;
  text-align: center;
}

.login__img img {
  width: 70%;
  object-fit: contain;
}

.login__form {
  padding: 50px;
  background: var(--secondary-color);
  position: relative;
}

.login__container {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  margin-top: 100px;
}

.login__form .user {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -9%;
  left: 50%;
  transform: translate(-50%, -9%);
}

.login__form .user img {
  width: 100px;
}

.login__form h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

.login__form {
  width: 40%;
}

.login__form input {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  color: #000000;
}

.login__form input:focus {
  outline: none;
}

.auth__btn {
  background: var(--heading-color) !important;
  width: 100%;
  color: #fff !important;
}

.login__form p {
  font-size: 0.9rem;
  color: #fff;
  margin-top: 1.5rem;
  text-align: center;
}

.login__form p a {
  color: var(--heading-color);
  text-decoration: none;
  margin-left: 5px;
  font-weight: 500;
}


.form__digit {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form__digit input{
  width: 35px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 0px 0px;
  background-color: #fff; /* White background */
  color: black; /* Text color */
  border: 2px solid #2d3748; /* Border color */
  border-radius: 8px; /* Rounded corners */
}

.back-to-login-button {
  display: flex;
  width: 100%;
  padding: 10px 20px;
  background-color: #16506b50;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}


/* =========================================== Account Css Start =========================*/
.account {
  margin: 0 0;
  background-color: #081b29;
}
.account-inner {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

/* Left Content Css */
.account-left {
  width: 40%;
  position: relative;
  z-index: 1;
  padding: 180px 120px 210px 100px;
  background-color: #72e8ec;
}

@media screen and (max-width: 1599px) {
  .account-left {
      padding: 180px 75px 210px 75px;
  }
}

@media screen and (max-width: 1499px) {
  .account-left {
      padding: 170px 70px 120px 70px;
  }
}

@media screen and (max-width: 1399px) {
  .account-left {
      padding: 160px 60px 120px 60px;
  }
}

@media screen and (max-width: 1199px) {
  .account-left {
      padding: 160px 30px 120px 30px;
  }
}

@media screen and (max-width: 991px) {
  .account-left {
      padding: 160px 15px 120px 15px;
  }
}

@media screen and (max-width: 767px) {
  .account-left {
      display: none;
  }
}

@media screen and (max-width: 575px) {
  .account-left {
      padding: 140px 30px;
  }
}

@media screen and (max-width: 424px) {
  .account-left {
      padding: 130px 20px;
  }
}

.account-left__thumb {
  position: absolute;
  left: 50px;
  z-index: -2;
}


@media screen and (max-width: 1199px) {
  .account-left__thumb {
      left: 0;
  }
}



.account-left__thumb-two {
  position: absolute;
  left: 50px;
  z-index: -2;
}

@media screen and (max-width: 1599px) {
  .account-left__thumb-two {
      bottom: 100px;
  }
}

@media screen and (max-width: 1499px) {
  .account-left__thumb-two {
      bottom: 150px;
  }
}

@media screen and (max-width: 1399px) {
  .account-left__thumb-two {
      bottom: 200px;
  }
}

@media screen and (max-width: 1199px) {
  .account-left__thumb-two {
      left: 0;
      bottom: 280px;
  }
}

@media screen and (max-width: 991px) {
  .account-left__thumb-two {
      bottom: 350px;
  }
}

.account-left__logo {
  position: absolute;
  top: 25px;
  width: 150px;
  z-index: -1;
}


@media screen and (max-width: 1399px) {
  .account-left__logo {
      left: 30px;
  }
}

.account-left__subtitle {
  color: hsl(var(--static-white));
  margin-bottom: 15px;
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .account-left__subtitle {
      font-size: 16px;
  }
}

.account-left__subtitle-two {
  display: inline-block;
  position: relative;
  z-index: 1;
  font-weight: 300;
  color: hsl(var(--static-white));
}

.account-left__subtitle-two img {
  position: absolute;
  bottom: 0;
  right: -60px;
}

.account-left__title {
  color: hsl(var(--static-white));
  font-weight: 500;
  position: relative;
}

@media screen and (max-width: 991px) {
  .account-left__title {
      font-size: 24px;
  }
}

.account-left__title::before {
  position: absolute;
  content: "";
  bottom: -4px;
  left: 5px;
  width: 66px;
  height: 4px;
  background-color: hsl(var(--static-white));
}

.account-left__title-two {
  color: hsl(var(--static-white));
}

.account-left__title-two-style {
  color: #F9EC31;
  text-shadow: 1px 3px #e84142;
  font-size: 48px;
  font-family: var(--heading-font);
  font-weight: 700;
}

.account-left__title-three {
  color: hsl(var(--static-white));
  font-size: 30px;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.account-left__title-three::before {
  position: absolute;
  content: "";
  left: 5px;
  bottom: -4px;
  width: 50px;
  height: 4px;
  background-color: hsl(var(--static-white));
}

/* Right Content Css */
.account-right {
  padding: 70px 230px;
}

@media screen and (max-width: 1599px) {
  .account-right {
      padding: 10px 200px;
  }
}

@media screen and (max-width: 1499px) {
  .account-right {
      padding: 10px 150px;
  }
}

@media screen and (max-width: 1399px) {
  .account-right {
      padding: 32px 90px;
  }
}

@media screen and (max-width: 1199px) {
  .account-right {
      padding: 32px 60px;
  }
}

@media screen and (max-width: 991px) {
  .account-right {
      padding: 32px 45px;
  }
}

@media screen and (max-width: 767px) {
  .account-right {
      padding: 40px;
  }
}

@media screen and (max-width: 575px) {
  .account-right {
      padding: 40px 0px;
  }
}

@media screen and (max-width: 424px) {
  .account-right {
      padding: 25px 0px;
  }
}

.account-right-wrapper {
  padding: 10px 30px;
  width: 60%;
  background-color: hsl(var(--white));
}

@media screen and (max-width: 991px) {
  .account-right-wrapper {
      padding: 10px 20px;
  }
}

@media screen and (max-width: 767px) {
  .account-right-wrapper {
      width: 100%;
      padding: 20px;
  }
}

.account-right-wrapper .theme-switch-wrapper {
  margin-left: 0;
}

@media screen and (max-width: 991px) {
  .account-right-wrapper .theme-switch-wrapper {
      order: 0;
  }
}

.account-right-wrapper .slider {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background-color: hsl(var(--black)/0.2);
}

.account-right-wrapper .slider i {
  color: hsl(var(--black));
  font-size: 20px;
}

.account-right-wrapper [data-theme=light] .theme-switch .slider i::before {
  content: "\f186";
  color: hsl(var(--static-black));
}

.continue-google {
  margin-bottom: 24px;
}

[data-theme=dark] .continue-google .btn:hover {
  background-color: #eee6 !important;
}

[data-theme=light] .continue-google .btn:hover {
  background-color: #151313c7 !important;
}

.continue-google .google-icon {
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 13px;
  color: #ffffff;
}

.continue-facebook .facebook-icon {
  color: #fff;
  margin-right: 5px;
  font-size: 16px;
}

/* Continue Google Css */
/* Account Content Css Start */
.account-content__top {
  margin-bottom: 60px;
  display: flex;
  justify-content: end;
  padding-right: 50px;
}

@media screen and (max-width: 1399px) {
  .account-content__top {
      margin-bottom: 60px;
      padding-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .account-content__top {
      margin-bottom: 50px;
      padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .account-content__top {
      margin-bottom: 20px;
  }
}

.btn {
  color: #13171ac7 !important;
  font-weight: 300;
  padding: 16px 48px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  text-align: center;
  border: 1px solid #72e8ec;
  font-family: #fff;
  line-height: 1;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.continue-google .btn {
  border: 1px solid #273949c7;
}

.continue-facebook .btn {
  border: 1px solid hsl(var(--black)/0.2);
}

.account-content__member {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .account-content__member {
      flex-wrap: wrap;
      justify-content: end;
  }
}

.account-content__member .account-link {
  color: hsl(var(--base));
}

.account-content__member .custom--dropdown>.custom--dropdown__selected {
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  padding-right: 30px;
}

@media screen and (max-width: 1399px) {
  .account-content__member .custom--dropdown>.custom--dropdown__selected {
      padding-right: 15px;
  }
}

.account-content__member .custom--dropdown>.custom--dropdown__selected .text {
  color: hsl(var(--black)) !important;
}

.account-content__logo {
  max-width: 200px;
}

.account-content__member-text {
  margin-right: 14px;
  font-weight: 500;
}

.account-link {
  transition: 0.2s;
}

.account-link:hover {
  text-decoration: underline;
}

/* Account Content Css End */
/* Account Form Css Start */
.account-form {
  max-width: 480px;
}

.account-form__desc {
  margin-bottom: 40px;
  color:#fff;
}

@media screen and (max-width: 991px) {
  .account-form__desc {
      margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .account-form {
      max-width: unset;
  }
}

/* Account Form Css End */

/* ================================= Form Css Start =========================== */
/* Form Label */
.form--label {
  margin-bottom: 6px;
  color: #fff;
  font-weight: 300;
}

.form-group {
  margin-bottom: 1.5rem;
}

/* Form Select */
.select {
  color: hsl(var(--white)/0.7) !important;
  background-color: transparent !important;
  height: 30px;
  border: 1px solid hsl(var(--white)/0.3);
  padding: 4px;
  border-radius: 5px;
}

.select:focus {
  border-color: #539daac7;
  color: hsl(var(--white)) !important;
}

.select option {
  background-color: hsl(var(--black));
  color: hsl(var(--white));
}

/* Form Select End */
/* Form Control Start */
.form--control {
  border-radius: 4px;
  font-weight: 400;
  outline: none;
  width: 100%;
  padding: 15px;
  background-color: transparent;
  border: 1px solid #5483a7c7;
  color: #fff;
  line-height: 1;
}

.form--control.style-two {
  border-color: hsl(var(--white)/0.2);
  padding: 5px;
  padding-left: 25px;
  color: hsl(var(--white));
}

.form--control.style-two:focus {
  color: hsl(var(--white)) !important;
}

.form--control.style-three {
  border-color: hsl(var(--white)/0.2);
  padding: 8px;
  padding-right: 70px;
  color: hsl(var(--body-color));
}

.form--control.style-three:focus {
  color: hsl(var(--body-color)) !important;
}

.form--control::placeholder {
  color: hsl(var(--body-color)/0.8);
}

.form--control:focus {
  border-radius: 4px;
  color: #fff;
  box-shadow: none;
  border-color: #fff !important;
  background-color: transparent;
}

.form--control:disabled,
.form--control[readonly] {
  background-color: #72e8ec;
  opacity: 1;
  border: 0;
}

.buy-sell__price .form--control:disabled,
.buy-sell__price .form--control[readonly] {
  background-color: #12272d;
}

.form--control[type=password] {
  color: #fff;
}

.form--control[type=password]:focus {
  color: #fff;
}

.form--control[type=file] {
  line-height: 50px;
  padding: 0;
  position: relative;
}

.form--control[type=file]::file-selector-button {
  border: 1px solid hsl(var(--black)/0.08);
  padding: 4px 6px;
  border-radius: 0.2em;
  background-color: hsl(var(--base)) !important;
  transition: 0.2s linear;
  line-height: 25px;
  position: relative;
  margin-left: 15px;
  color: #fff !important;
}

.form--control[type=file]::file-selector-button:hover {
  background-color: hsl(var(--base));
  border: 1px solid hsl(var(--base));
  color: #fff;
}

/* Form Control End */
textarea.form--control {
  height: 150px;
}

/* Autofill Css */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

[data-theme=light] input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-text-fill-color: hsl(var(--black)) !important;
}

/* Autofill Css End */
/* input gruop */
.input--group {
  position: relative;
}

/* Show Hide Password */
.password-show-hide {
  position: absolute;
  right: 20px;
  z-index: 5;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  color: hsl(var(--black));
}

/* --------------- Number Arrow None --------------------- */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Custom Checkbox Design */
.form--check {
  display: flex;
  flex-wrap: wrap;
}

.form--check a {
  display: inline;
}

.form--check .form-check-input {
  box-shadow: none;
  background-color: transparent;
  box-shadow: none !important;
  border: 0;
  position: relative;
  border-radius: 2px;
  width: 14px;
  height: 14px;
  border: 1px solid hsl(var(--base));
  cursor: pointer;
}

.form--check .form-check-input:checked {
  background-color: hsl(var(--base)) !important;
  border-color: hsl(var(--base)) !important;
  box-shadow: none;
}

.form--check .form-check-input:checked[type=checkbox] {
  background-image: none;
}

.form--check .form-check-input:checked::before {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: hsl(var(--white));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form--check .form-check-label {
  width: calc(100% - 14px);
  padding-left: 8px;
  cursor: pointer;
}

@media screen and (max-width: 424px) {
  .form--check label {
      font-size: 0.9375rem;
  }
}

@media screen and (max-width: 424px) {
  .form--check a {
      font-size: 0.9375rem;
  }
}

.login-btn {
  background: #19292e;
  border: 1px solid #19292e;

}

.login-btn span {
  color: hsl(var(--body-color));
}

.login-btn:hover {
  border: 1px solid #19292e;
}

.login-btn a {
  color: hsl(var(--base));
}

/* Custom Radion Design */
.form--radio .form-check-input {
  box-shadow: none;
  border: 2px solid hsl(var(--base));
  position: relative;
  background-color: transparent;
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.form--radio .form-check-input:active {
  filter: brightness(100%);
}

.form--radio .form-check-input:checked {
  background-color: transparent;
  border-color: hsl(var(--base));
}

.form--radio .form-check-input:checked[type=radio] {
  background-image: none;
}

.form--radio .form-check-input:checked::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 7px;
  height: 7px;
  background-color: hsl(var(--base));
  border-radius: 50%;
  z-index: 999;
}

.form--radio .form-check-label {
  width: calc(100% - 18px);
  padding-left: 5px;
  cursor: pointer;
}

/*  Custom Switch Design */
.form--switch .form-check-input {
  border-radius: 3px;
  background-image: none;
  position: relative;
  box-shadow: none;
  border: 0;
  background-color: hsl(var(--black)/0.2) !important;
  padding: 10px !important;
  margin-left: 0;
  margin-bottom: 5px;
  border-radius: 40px;
  width: 70px;
  height: 38px;
  cursor: pointer;
}

.form--switch .form-check-input:focus {
  border-radius: 40px;
  background-image: none;
  position: relative;
  box-shadow: none;
  border: 0;
}

.form--switch .form-check-input::before {
  position: absolute;
  content: "";
  width: 28px;
  height: 28px;
  background-color: hsl(var(--white));
  top: 50%;
  transform: translateY(-50%);
  border-radius: 2px;
  left: 5px;
  border-radius: 50%;
  transition: 0.2s linear;
}

.form--switch .form-check-input:checked {
  background-color: hsl(var(--base)) !important;
}

.form--switch .form-check-input:checked::before {
  left: calc(100% - 33px);
  background-color: hsl(var(--white)) !important;
}

.form--switch .form-check-input:checked[type=checkbox] {
  background-image: none;
}

.form--switch .form-check-label {
  width: calc(100% - 14px);
  padding-left: 5px;
  cursor: pointer;
}

/*  Custom Switch End Design */
::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

/* ================================= Form Css End =========================== */

.btn--base {
  background-color: #269ff5 !important;
  color: #fff !important;
}

.btn--base:hover,
.btn--base:focus .btn--base:focus-visible {
  background-color: #052836c7 !important;
  border: 1px solid #5fadd1b0 !important;
  color: #fff !important;
}

.btn--base.outline {
  background-color: transparent !important;
  border: 1px solid  !important;
  color: #fff !important;
}

.btn--base.outline:hover,
.btn--base.outline:focus .btn--base.outline:focus-visible {
  background-color: hsl(var(--base)) !important;
  color: #fff !important;
}

.btn--base-two {
  background-color: hsl(var(--base-two)) !important;
  border: 1px solid hsl(var(--base-two)) !important;
  color: hsl(var(--white)) !important;
}

.btn--base-two:hover,
.btn--base-two:focus .btn--base-two:focus-visible {
  background-color: hsl(var(--base-two-d-100)) !important;
  border: 1px solid hsl(var(--base-two)) !important;
  color: hsl(var(--white)) !important;
}

.btn--base-two.outline {
  background-color: transparent !important;
  border: 1px solid hsl(var(--base-two)) !important;
  color: hsl(var(--base-two)) !important;
}

.btn--base-two.outline:hover,
.btn--base-two.outline:focus .btn--base-two.outline:focus-visible {
  background-color: hsl(var(--base-two)) !important;
  color: hsl(var(--white)) !important;
}


/* Or Other Option Css Start */
.other-option {
  margin: 30px 0 30px;
  position: relative;
  text-align: center;
  z-index: 1;
}

@media screen and (max-width: 1399px) {
  .other-option {
      margin: 30px 0 20px;
  }
}

@media screen and (max-width: 1199px) {
  .other-option {
      margin: 20px 0 15px;
  }
}

.other-option::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: #82cfcbc7;
  z-index: -1;
}

.other-option__text {
  background-color: #081b29;
  color: #fff;
  display: inline-block;
  padding: 0 16px;
  font-family: var(--body-font);
}


@media only screen and (max-width: 768px) {
  .login__img {
    display: none;
  }

  .login__form {
    width: 70%;
    margin: auto;
  }

  .login__container {
    padding-bottom: 3rem;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 576x) {
  .login__form {
    width: 100%;
  }

  .login__container {
    padding-bottom: 1rem;
  }
}
