
.chart{
  background: linear-gradient(to bottom, #112e42, #081b29);
  max-height: 100%;
}
.main-content {
  display: flex;
  justify-content: center;
}

/* Center Chart */
.chart__center {
  margin-left: 5%;
  width: 60%;
  padding: 1rem;
}

.chart__center p {
  color: var(--text-color);
  line-height: 1.6;
}

/* Right sidebar */

.right__sidebar {
  margin-right: 3%;
  width: 350px;
  height: 50vh;
}

.right__sidebar p {
  color: var(--text-color);
  line-height: 1.6;
}

.trending__heding{
  margin-top: 15%;
  color: var(--text-color);
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  text-justify: center;
  justify-content: center;
}

.trending__content {
  height: 45vh;
  overflow-y: scroll;
  scrollbar-width: none;
}



/* Sidebar Inner */

.sidebar__inner {
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
}

.sidebar__inner::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Header Sidebar */
.right__sidebar h2 {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding: 10px 0;
}

.chart__center {
  display: flex;
  flex-direction: column;
}

.chart__container {
  display: flex;
  flex-direction: column;
}



.chartWrapper {
  position: relative;
  width: 100%;
  height: 500px;
}

.responsiveChartContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsiveChart {
  width: 100%;
  height: 100%;
}

.chart__content {
  padding: 20px;
  border-radius: 8px;
}


/* Button Rightbar */
.mainNavigate {
  border-top: 1px solid #222531;
  border-bottom: 1px solid #222531;
  display: flex;
  gap: 0.5rem;
  margin: 0 15% 0 15%;
  align-items: center;
}

.contentcenter {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
}

.mainNavigate .btnNavigate {
  border-radius: 0.8rem;
  padding: 10px 20px;
  color: var(--text-color);
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}

.mainNavigate .btnNavigate:hover {
  background: var(--bg-button-color);
}

.mainNavigate .active {
  background-color: var(--secondary-color);
}

.mainNavigate .active:hover {
  background-color: var(--secondary-color);
}

.news-container {
  max-height: 85%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.news-container::-webkit-scrollbar {
  display: none;
}

.tradingview-widget-copyright{
  display: none;
}

.mini-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #c74f18;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.mini-button:hover {
  background-color: #c74f18;
}

.nav__sideBar {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 5%;
  left: 0;
  background: #081b29;
  z-index: 999;
  overflow-y: auto; 
  max-height: 100%;
  scrollbar-width: none;
  display: none;
}

/* navigate rightbar */
.navigatebarxx {
  width: 300px; /* Same width as right__sidebar */
  height: 50vh; /* Full height */
  position: fixed;
  top: 0;
  right: -300px; /* Start off-screen */
  background-color: #fff; /* Adjust background color as needed */
  transition: right 0.3s ease-in-out; /* Smooth transition */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}


@media only screen and (max-width: 992px) {
  .right__sidebar {
    width: 250px;
  }
  .chart__center {
    padding-left: 10%;
    width: 75%;
    height: 40%;
  }
  .chartWrapper {
    height: 300px;
  }
  .trending__content {
    height: 35vh;
  }
}


@media only screen and (max-width: 678px) {

  .right__sidebar {
    width:  20%;
  }

  .chart__center {
    margin-left: 5%;
    width: 70%;
    padding-left: 30px;
  }

  .trending__heding {
    font-size: 0.9rem;
  }
  .chartWrapper {
    height: 250px;
  }

  .trending__content {
    height: 30vh;
  }
}

@media only screen and (max-width: 576px) {


  .right__sidebar {
    margin-right: 0;
    padding: 0;
    height: 40vh;
    width:  22%;

  }
  .chart__center {
    margin-left: 0;
    width: 70%;
    padding: 0.5rem;
  }

  .chartWrapper {
    height: 230px;
  }

  .responsiveChart {
    width: 50%;
    height: 300px;
  }

  .mainNavigate .btnNavigate {
    border-radius: 0.8rem;
    padding: 5px 10px;
    color: var(--text-color);
    font-weight: 500;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .trending__content {
    height: 25vh;
  }

}
