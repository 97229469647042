/* styles.css */
.password-strength-meter {
    margin-top: 0.5rem;
}

.password-strength-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
}

.password-strength-text {
    font-size: 0.75rem;
    color: #9CA3AF;
}

.password-strength-bars {
    display: flex;
    gap: 0.25rem;

}

.password-strength-bar {
    height: 0.25rem;
    width: 25%;
    border-radius: 0.25rem;
    transition: background-color 0.3s;
}

.bg-gray-600 {
    background-color: #323332; 
}

/* styles.css */
.bg-red-500 {
    background-color: #f80707; /* Custom red color */
}

.bg-red-400 {
    background-color: #f56565; /* Custom lighter red color */
}

.bg-yellow-500 {
    background-color: #fc8181; /* Custom yellow color */
}

.bg-yellow-400 {
    background-color: #4bb93c; /* Custom lighter yellow color */
}

.bg-green-500 {
    background-color: #48bb78; /* Custom green color */
}


/* PasswordCriteria */
.criteria-container {
    margin-top: 0.5rem;
    gap: 0.25rem;
}

.criteria-item {
    display: flex;
    align-items: center;
    font-size: 0.75rem; 
}

.icon-met {
    width: 1rem; 
    height: 1rem; 
    color: #10B981; 
    margin-right: 0.5rem; 
}

.icon-not-met {
    width: 1rem; 
    height: 1rem;
    color: #6B7280; 
    margin-right: 0.5rem; 
}

.text-met {
    color: #10B981;
}

.text-not-met {
    color: #9CA3AF;
}