.cowcard {
  display: flex;
  position: absolute;
  width: 450px;
  margin-top: 160px;
  margin-left: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  transition: all 0.3s ease-in-out;
}

.cowcard__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: inherit;
  height: inherit;
  border: 2px solid #00abf0; 
  border-radius: 20px;
  background-color: #11111120;
  /* box-shadow: 0 0 8px #ffffff25; */
}

.cowcard__content h2 {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--secondary-color);
  text-align: center;
  display: flex;
  margin-top: 10px;
}

.cowcard__content p {
  font-family: "Times New Roman", Times, serif;
  font-size: 1rem;
  color: var(--text-color);
  text-align: center;
  justify-items: center;
  line-height: 1.8rem;
}

.cowcard__content span {
  font-size: 1rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.cow__value {
  font-size: 0.7rem;
  font-weight: 400;
}

/* converter */

.cow-row {
  display: flex;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.custom-convert {
  width: 15px;
  height: 15px;
  margin: 0 10px;
}

.coin {
  background-color: #081b29;
  display: flex;
  align-items: center;
}

.usd {
  background-color: #61c9db70;
}

.icon img {
  width: 24px;
  height: 24px;
}

.coin,
.usd {
  display: flex;
  align-items: center;
}

.coin .symbol,
.usd .symbol {
  font-size: 1rem;
  color: var(--text-color);
}

.coin .fullName,
.usd .fullName {
  font-size: 0.8rem;
  font-weight: 450;
  justify-items: auto;
  color: var(--text-color);
}

.coin .icon,
.usd .icon {
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}

.coin input,
.usd input {
  color: var(--text-color);
  flex: 1;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: end;
  font-size: 1rem;
  font-weight: 450;
}


/* dropdown test */
.currency-selector {
  position: relative;
  display: inline-block;
  margin: 0 10px ;
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu-custom {
  position: absolute;
  background-color: #11111120;
  border: 1px solid #ccc;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 55px; 
  max-height: 30vh; 
  overflow-y: auto; 
  scrollbar-width: none;

}

.dropdown-item-custom {
  padding: 5px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-item img {
  margin-right: 8px;
  width: 20px; /* Adjust image size as needed */
  height: 20px; /* Adjust image size as needed */
}
/* @media only screen and (max-width: 1300px) {
  .cowcard {
    width: 350px;
    margin-left: 20px;
  }
  .cowcard__content {
    background-color: transparent;
  }

  .cowcard__content h2 {
    font-size: 1.1rem;
  }

  .cowcard__content p {
    font-size: 0.9rem;
    padding: 0 20px;
  }
  .cowcard__content span {
    font-size: 1rem;
    font-weight: 300;
  }
} */

@media only screen and (max-width: 992px) {
  .cowcard {
    width: 60%;
  }
  .cowcard__content {
    background-color: transparent;
  }
  .cowcard__content h2 {
    font-size: 1rem;
  }
  .cowcard__content p {
    font-size: 0.9rem;
    padding: 0 10px;
  }
  
}

@media only screen and (max-width: 576px) {
  .average-price {
    display: none;
  }
  .cowcard {
    width: 60%;
  }

  .cow{
    font-size: 0.1rem;
  }

  .custom-convert {
    width: 10px;
    height: 10px;
    margin: 0 1px;
  }

  .cowcard__content {
    background-color: transparent;
  }
  .cowcard__content h2 {
    font-size: 0.8rem;
  }
  .cowcard__content p,
  .cowcard__content span {
    font-size: 0.8rem;
    padding: 0 10px;
  }

  .currency-selector {
    margin: 0 5px;
  }

  .dropdown-menu-custom {
    width: 30px;
  }

  .dropdown-item-custom {
    padding: 2px 2px;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item img {
    margin-right: 5px;
    width: 13px; 
    height: 13px; 
  }
}
