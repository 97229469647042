.cardDetail {
  border: 1px solid #55c7e4;
  padding: 0.1rem 0.2rem;
  border-radius: 1rem;
  width: 100%;
  background-color: #11111120;
  text-align: center;
  margin-top: 5px;
}

/* value name and code */
.cardDetail__currency {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-left: 5%;
  color: var(--text-color);
}

.currency-name,
.currency-code {
  display: block;
  text-align: center;
}

.cardDetail__columns {
  display: flex;
  justify-content: space-between;
}

/* value rate and  percentchange */
.cardDetail__change {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-left: 5%;
  color: var(--text-color);
}

/* .cardDetail__column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
 
} */

.cardDetail__change--price {
  font-size: 1rem;
  color: var(--text-color);
  align-items: center;
}

.cardDetail__change--valuechange {
  font-size: 1rem;
}

.red {
  color: #b30808;
}

.green {
  color: green;
}

/* response */

@media only screen and (max-width: 1200px) {
  .cardDetail__currency {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .cardDetail__change--price,
  .cardDetail__change--valuechange {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 992px) {
  .cardDetail__currency {
    font-size: 0.5rem;
    font-weight: 400;
  }

  .cardDetail__change--price,
  .cardDetail__change--valuechange {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 678px) {
  .card__chart {
    display: none;
  }

  .cardDetail__currency,
  .cardDetail__change {
    font-size: 0.6rem;
    font-weight: 400;
    width: 50%;
  }

  .cardDetail__change--price,
  .cardDetail__change--valuechange {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 576px) {
  /* heading  Trending */
  .trending__heding{
    font-size: 0.8rem;
  }
  /* currency code */
  .currency-code {
    display: none;
  }
  .cardDetail__currency{
    width: 35%;
  }

}
