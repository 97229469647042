.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contentz {
  flex: 1;
}

/* .top-nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #123;
  background-image: linear-gradient(to bottom, #0003, transparent);
  padding: 0 10%;
  font-size: 1rem;
  font-weight: 300;
  max-height: 60%;
}


.top-nav-links> a {
  color: #9ab;
  text-align: center;
  text-decoration: none;
  transition: all .5s;
  max-width: 60%;
}

.top-nav-links > a:hover {
  background: #ffffff06;
  color: #adf;
}

.top-nav-links > .line {
  background: #68a;
  height: 1px;
  pointer-events: none;
  opacity: 0;
  transition: all .5s;
} */

.top-nav-links {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #123;
  background-image: linear-gradient(to bottom, #0003, transparent);
  padding: 0 10%;
  font-size: 1rem;
  font-weight: 500;
  width: 100%; /* Set the width to 100% */
  height: 60px; /* Set the height to 60px */
  max-height: 60px; /* Set the max-height to 60px */
}

.top-nav-links > a {
  color: rgb(197, 126, 32);
  text-align: center;
  text-decoration: none;
  transition: all .5s;
  height: 60px; 
  line-height: 60px;
}

.top-nav-links > a:hover {
  background: #ffffff06;
  color: #adf;
}

.top-nav-links > .line {
  background: #68a;
  height: 1px;
  pointer-events: none;
  opacity: 0;
  transition: all .5s;
}

.ri-home-line {
  font-size: 35px;
}
@media screen and (max-width: 734px) {

  .top-nav-links {
    display: flex;
  }

  

}