.footer {
    padding-top: 30px;
    background-color: var(--bg-color);
}

.footer .logo img {
    width: 50%;
    margin-bottom: 1rem;
}

.footer .logo p {
    color: var(--text-color);
}

.social__links span a {
    text-decoration: none;
    color: var(--heading-color);
    font-size: 1.2rem;
}


.footer__link-title {
    color: var(--heading-color);
    margin-bottom: 1rem;

}


.footer__quick-links li a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1.1rem;

}

.footer__quick-links h6 span i {
    color: var(--secondary-color);
    font-size: 1.1rem;
}

.copyright {
    background-color: var(--bg-color);
    color: var(--text-color);
    font-size: 1rem;
}

.text-center {
    text-align: center;
}